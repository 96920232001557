.absolute-fill {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.center {
	display: flex;
	width: 367px;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	justify-content: center;
	align-items: center;
}

.image-container {
	font-size: 115%;
	transform: rotate(5deg);
	transform-origin: center;
	padding: 30px;
	border: 1px solid gray;
	box-shadow: 10px 10px 44px 4px rgba(0,0,0,0.75);
	-webkit-box-shadow: 10px 10px 44px 4px rgba(0,0,0,0.75);
	-moz-box-shadow: 10px 10px 44px 4px rgba(0,0,0,0.75);
	border-radius: 5px;
	overflow: hidden;
	background-color: rgb(240, 240, 240);
	background-size: 120px;
	background-repeat: no-repeat;
	background-position: bottom 16px right 16px;
	background-image: url("logo.png");
}
.the-image {
	aspect-ratio: 1/1;
}
.author-name {
	font-weight: 700;
	color: rgb(102, 95, 87);
}
.author-by {
	font-weight: 500;
}
.prompt {
	font-style: normal;
}