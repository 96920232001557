@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
	font-family: 'Satoshi';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		url('./assets/satoshi/Satoshi-Regular.woff2') format('woff2'),
		url('./assets/satoshi/Satoshi-Regular.woff') format('woff');
}

html {
	font-family: 'Satoshi';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'Satoshi';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}